import { parseDate } from "chrono-node";
import { startOfDay } from "date-fns";
import { ReactElement } from "react";
import { DateTimeFormatHandler } from "../../../hooks/useDateTimeFormatter";
import BulbIcon from "../../../img/bulb-icon.svg";
import CoffeeIcon from "../../../img/coffee.svg";
import CompassIcon from "../../../img/compass-icon.svg";
import ElectricIcon from "../../../img/lightning-bolt.svg";
import ChatIcon from "../../../img/message-square.svg";
import SendIcon from "../../../img/send-icon.svg";
import SmileIcon from "../../../img/smile.svg";
import SparkleIcon from "../../../img/sparkle-icon.svg";
import StarIcon from "../../../img/star-outline.svg";
import SunIcon from "../../../img/sun.svg";
import { DelayStart } from "../../../reclaim-api/scheduling-links/scheduling-links-client";
import {
  CreateSchedulingLinkRequest,
  PatchSchedulingLinkRequest,
  SchedulingLink,
  SchedulingLinkIconType,
  TimePolicyType,
} from "../../../reclaim-api/scheduling-links/SchedulingLinks";
import { defaultTimePolicy } from "../../../reclaim-api/Users";
import { MILLISECONDS_PER_MINUTE } from "../../../utils/dates";
import { arrayOfAll } from "../../../utils/typescript";
import { StrongSelectItem } from "../StrongSelect";
import { SchedulingLinkFormFieldValues } from "./SchedulingLinkForm.types";

const DEFAULT_MEETING_TITLE = "{organizer} and {attendee}";

export const defaultFieldValues = (): SchedulingLinkFormFieldValues => ({
  title: "",
  description: "",
  timePolicyType: "MEETING",
  oneOffPolicy: undefined,
  durations: [30 * MILLISECONDS_PER_MINUTE],
  defaultDuration: undefined,
  daysIntoFuture: 60,
  startDate: undefined,
  endDate: undefined,
  priority: "DEFAULT",
  conferenceType: "NONE",
  iconType: "LIGHT",
  slug: "",
  location: "",
  delayStartType: DelayStart.FROMNOWHOURS,
  delayStartUnits: 4,
  coOrganizers: [],
  linkGroupId: "root",
  meetingTitle: DEFAULT_MEETING_TITLE,
});

export const schedulingLinkGroupIdToFieldValues = (
  link: SchedulingLink
): SchedulingLinkFormFieldValues["linkGroupId"] => {
  if (link.hidden) return "hidden";
  return link.linkGroupId || "root";
};

export const schedulingLinkToFieldValues = (
  format: DateTimeFormatHandler,
  link: SchedulingLink
): SchedulingLinkFormFieldValues => ({
  title: link.title || "",
  iconType: link.iconType,
  description: link.description || "",
  timePolicyType: link.timePolicyType,
  oneOffPolicy: link.oneOffPolicy || undefined,
  durations: link.durations.sort((a, b) => a - b).map((l) => l * MILLISECONDS_PER_MINUTE),
  defaultDuration: !!link.defaultDuration ? link.defaultDuration * MILLISECONDS_PER_MINUTE : undefined,
  daysIntoFuture: link.daysIntoFuture || undefined,
  startDate: !!link.startDate ? format(link.startDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  endDate: !!link.endDate ? format(link.endDate, "DATE_YEAR_DISPLAY_FORMAT") : undefined,
  conferenceType: link.conferenceType || "NONE",
  priority: link.priority || "DEFAULT",
  slug: link.slug || "",
  location: link.location || "",
  delayStartType: !!link?.delayStartUnits ? link?.delayStart : DelayStart.FROMNOWHOURS,
  delayStartUnits: link?.delayStartUnits ? link?.delayStartUnits : 4,
  coOrganizers:
    link.coOrganizers?.map((org) => {
      if (!org.member.userId) throw new Error(`Organizer "${org.member.email}" did not have a userId`);
      return {
        userId: org.member.userId,
        timePolicyType: org.timePolicyType,
        oneOffPolicy: org.oneOffPolicy || defaultTimePolicy(),
      };
    }) || [],
  linkGroupId: schedulingLinkGroupIdToFieldValues(link),
  meetingTitle: link.meetingTitle || DEFAULT_MEETING_TITLE,
});

export const fieldValuesToCreateSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): CreateSchedulingLinkRequest => ({
  title: values.title,
  description: values.description,
  enabled: true,
  timePolicyType: values.timePolicyType,
  oneOffPolicy: values.oneOffPolicy || undefined,
  durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
  defaultDuration: !!values.defaultDuration ? values.defaultDuration / MILLISECONDS_PER_MINUTE : undefined,
  daysIntoFuture: Number(values.daysIntoFuture) || undefined,
  startDate: !!values.startDate ? startOfDay(parseDate(values.startDate)) : undefined,
  endDate: !!values.endDate ? startOfDay(parseDate(values.endDate)) : undefined,
  priority: values.priority,
  conferenceType: values.conferenceType === "NONE" ? undefined : values.conferenceType,
  iconType: values.iconType,
  slug: values.slug,
  location: values.location,
  delayStart: values.delayStartType,
  delayStartUnits: Number(values.delayStartUnits) || undefined,
  members: values.coOrganizers?.length
    ? values.coOrganizers.map((org) => ({
        ...org,
        oneOffPolicy: org.timePolicyType === "ONE_OFF" ? org.oneOffPolicy : undefined,
      }))
    : undefined,
  hidden: values.linkGroupId === "hidden",
  linkGroupId: (() => {
    switch (values.linkGroupId) {
      case "hidden":
      case "root":
        return undefined;
      default:
        return values.linkGroupId;
    }
  })(),
  meetingTitle: values.meetingTitle,
});

export const fieldValuesToPatchSchedulingLinkRequest = (
  values: SchedulingLinkFormFieldValues
): PatchSchedulingLinkRequest => ({
  delayStartUnits: Number(values.delayStartUnits) || null,
  title: values.title,
  description: values.description || null,
  enabled: true,
  timePolicyType: values.timePolicyType,
  oneOffPolicy: values.oneOffPolicy || undefined,
  durations: values.durations.map((l) => l / MILLISECONDS_PER_MINUTE),
  defaultDuration: !!values.defaultDuration ? values.defaultDuration / MILLISECONDS_PER_MINUTE : null,
  daysIntoFuture: Number(values.daysIntoFuture) || null,
  delayStart: values.delayStartType,
  startDate: !!values.startDate ? startOfDay(parseDate(values.startDate)) : null,
  endDate: !!values.endDate ? startOfDay(parseDate(values.endDate)) : null,
  priority: values.priority,
  conferenceType: values.conferenceType === "NONE" ? null : values.conferenceType,
  iconType: values.iconType,
  slug: values.slug,
  location: values.location,
  members:
    values.coOrganizers &&
    (values.coOrganizers.length
      ? values.coOrganizers.map((org) => ({
          ...org,
          oneOffPolicy: org.timePolicyType === "ONE_OFF" ? org.oneOffPolicy : undefined,
        }))
      : undefined),
  hidden: values.linkGroupId === "hidden",
  linkGroupId: (() => {
    switch (values.linkGroupId) {
      case "hidden":
      case "root":
        return null;
      default:
        return values.linkGroupId;
    }
  })(),
  meetingTitle: values.meetingTitle,
});

export type SchedulingLinkIconData<K extends SchedulingLinkIconType = SchedulingLinkIconType> = {
  key: K;
  icon: ReactElement;
};

export const SCHEDULING_LINKS_ICON_ORDER = arrayOfAll<SchedulingLinkIconType>()([
  "LIGHT",
  "COFFEE",
  "BOLT",
  "COMMENT",
  "STAR",
  "AIRPLANE",
  "TWINKLE",
  "COMPASS",
  "SUN",
  "SMILE",
]);

export const SCHEDULING_LINKS_ICON_MAP: { [K in SchedulingLinkIconType]: SchedulingLinkIconData<K> } = {
  LIGHT: { key: "LIGHT", icon: <BulbIcon /> },
  COFFEE: { key: "COFFEE", icon: <CoffeeIcon /> },
  BOLT: { key: "BOLT", icon: <ElectricIcon /> },
  COMMENT: { key: "COMMENT", icon: <ChatIcon /> },
  STAR: { key: "STAR", icon: <StarIcon /> },
  AIRPLANE: { key: "AIRPLANE", icon: <SendIcon /> },
  TWINKLE: { key: "TWINKLE", icon: <SparkleIcon /> },
  COMPASS: { key: "COMPASS", icon: <CompassIcon /> },
  SUN: { key: "SUN", icon: <SunIcon /> },
  SMILE: { key: "SMILE", icon: <SmileIcon /> },
};

export const SCHEDULING_LINKS_ICONS: SchedulingLinkIconData[] = SCHEDULING_LINKS_ICON_ORDER.map(
  (key) => SCHEDULING_LINKS_ICON_MAP[key]
);

export const timePolicyTypeSelectOptions = (): StrongSelectItem<TimePolicyType>[] => [
  { value: "MEETING", label: "Meeting" },
  { value: "WORK", label: "Working" },
  { value: "PERSONAL", label: "Personal" },
  { value: "ONE_OFF", label: "Custom" },
];
